import React from "react";
import "./Nav.css";
import smlogo from "./GCTsm.png";

export const Nav = () => {
	return (
		<div className="navbody">
			<div className="navbar">
				<div className="navbarcontainer">
					
					<h3 className="logotxt">
						<span className="ss">StartUp Gateway Stories</span>
					</h3>
				</div>
			</div>
		</div>
	);
};
